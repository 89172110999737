interface User {
	admin?: boolean | null;
	coachProfile?: object | null;
	ambassadorProfile?: object | null;
	partnerProfile?: object | null;
	studentProfile?: object | null;
}

export function userType(
	user?: User | null,
): 'admin' | 'coach' | 'student' | 'ambassador' | 'partner' | undefined {
	if (user?.admin) {
		return 'admin';
	} else if (user?.coachProfile != null) {
		return 'coach';
	} else if (user?.ambassadorProfile != null) {
		return 'ambassador';
	} else if (user?.partnerProfile != null) {
		return 'partner';
	} else if (user?.studentProfile != null) {
		return 'student';
	}
	return undefined;
}
